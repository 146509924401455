import React from "react"

import Body from '../components/layout/default/main'
import JSONLint from '../components/widgets/json-lint/json-lint'

export default () => (
    <Body>
        <h3> Lint, Validate, and Prettify JSON :D</h3>
        <h4>Less steps than any other tool!</h4>
        <JSONLint />
        <h3> What is this tool? </h3>
        <p> How many times have you needed to Prettify JSON on someone else's computer?</p>
        <p> When did you last need to Lint JSON but didn't have any existing tools open?</p>
        <p> Have some minified JSON on your clipboard right now? Just hit cmd+v</p>
    </Body>
)
