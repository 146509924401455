import React from "react"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from "./jsonlint.module.css"
// import 'bootstrap/dist/css/'

/**
 * Features:
 * 1 - Lint and Format JSON (Done)
 *      - Do this automatically without a button push
 * 2 -  Exposing an options interface
 *      - It could be optionally displayed for users to set their preference
 *      - Store a cookie with theese prefrences
 * 3 - Autofix errors
 *      - When Linting fails, it'll ask if you'd like to fix it
 * 4 - Multi-JSON formats
 */

class JSONLint extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: "",
        }

        this.handleChange = this.handleChange.bind(this);
    }

    getMaxRows() {
        const minRowCount = 40
        const rowBuffer = 10

        let rowCount = (this.state.value || "").split('\n').length
        if (rowCount < minRowCount) {
            return minRowCount
        }

        return rowCount + rowBuffer
    }

    alert(msg, type) {
        const toastId = msg+type
        
        const opts =  {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId
        }

        switch(type) {
            case 'error':
                toast.error(msg, opts)
            break;
            case 'success':
                toast.success(msg, opts)
            break;
            default:
                break;
        }
    }

    clearAlerts() {
        toast.dismiss()
    }

    validateJson(rawString) {
        return JSON.stringify(JSON.parse(rawString), null, 2)
    }

    handleChange(event) {
        const raw = event.target.value
        let value = raw
        
        if (raw) {
            try {
                value = this.validateJson(raw)
                this.clearAlerts()
                this.alert("Valid JSON!", "success")
            } catch (e) {
                this.alert(e.message, 'error')
            }    
        }

        this.setState({ value });
    }

    render() {
        return (
            <div>
                <ToastContainer style={{ "padding-top": "40px" }} position="top-center" limit={4}></ToastContainer>
                <textarea autoFocus={true} value={this.state.value}
                    className={styles.jsonlinttextarea}
                    onChange={this.handleChange} rows={this.getMaxRows()} />

            </div>)
    }
}

export default JSONLint
